@import url("./App.css");

.lander{
  background-image: url("../images/background.png");
  height:95vh;
  display:flex;
  justify-content: flex-start;
  background-size: 60%;
  background-repeat: repeat;
  position:relative;
}

.right-side{
  width:50%;
  display:flex;
  justify-items: center;
  align-items: center;
  padding-right:4em;
}

.right-side img{
  width:100%;
}

.left-side{
  width:50%;
  text-align: left;
  color:var(--cream);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: var(--Oswald);
  padding-left:4em;
}

.left-side img{
  width:100%;
  max-width:900px;
}
.graphic-mb{
  display: none;
}
.logo-mb{
  display: none;
}
@media (orientation: portrait) {
  .graphic-dt{
    display:none;
  }
  .left-side{
    width: 100%;
    padding-left:0;
    align-items: center;
    text-align: center;
  }
  .right-side{
    width:0;
    padding-right: 0;
  }
  .logo-dt{
    display:none;
  }
  .graphic-mb{
    width:100%;
    display:block;
  }
  .left-side img {
    width:80%;
  }
  .header-subtext{
    margin-left:0 !important;
    margin-right:0 !important;
    margin-top:1em;
    margin-bottom: 1em;
  }
  .logo-mb{
    display: block;
    width:9em;
    position: absolute;
    left:1em;
    top:1em;
    margin-top:1.5em;
  }
}

.header-subtext{
  font-size:3em;
  font-weight: 500;
  margin: 0.5em;
  margin-left: 0;
}

.button{
  min-width:50%;
  outline:none;
  border:none;
  color:var(--cream);
  font-family:var(--Oswald);
  font-size:2em;
  cursor: pointer;
}

.button-text{
  margin:0;
  margin-top:.5em;
  margin-bottom:.5em;
  font-weight: 500;
}
.button-green{
  background-color: var(--green);
}

.top-right{
  position: absolute;
  right: 1em;
  top: 1em;
}

.youtube-login{
  color: white;
  background-color: red;
  padding: 0.2em;
  font-size: 1.3em;
  font-weight: 600;
  font-family: 'OSWALD';
  cursor: pointer;
  display:inline-block;
}

.youtube-login-large{
  margin-top:2em;
  font-size:2em;
}

.down-arrow{
  position:absolute;
  left:50%;
  bottom:3em;
  transform: translateX(-50%);
}

h1{
  background-color: var(--green);
  padding:0.5em;
  width:90%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  margin-top:2em;
}

h1 p{
  margin:0;
  margin-left:0.5em;
  color:var(--cream);
}

.text{
  max-width: 1600px;
  font-family: var(--Oswald);
  color:var(--light-grey);
  text-align: left;
  line-height: 1.6;
  font-size:1.5em;
  margin-top:2em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  max-width: 80vw;
}

