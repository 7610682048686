@import url('https://fonts.googleapis.com/css2?family=Erica+One&family=Oswald:wght@400;500&display=swap');
*{
  box-sizing: border-box;
}

html{
  --cream: #FFF3B9;
  --green: #478070;
  --dark-grey: #393939;
  --light-grey:#E4E4E4;
  --white: #FFFFFF;
  --red: #D14B4B;
  --Oswald: 'Oswald', sans-serif;
  background-color:var(--dark-grey)
}

@media screen and (max-width: 800px){
  html{
    font-size:0.8em;
  }
}
@media screen and (max-width: 600px){
  html{
    font-size:0.6em;
  }
}
@media screen and (max-width: 500px){
  html{
    font-size:0.4em;
  }
}

.cream{
  color:var(--cream);
}

.dark-grey{
  color:var(--dark-grey);
}

.App {
  text-align: center;
  background-color: var(--dark-grey);
  padding-bottom:5em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
