@import url("./App.css");

a{
  text-decoration: none;
}

.balance{
  background-color:var(--green);
  font-family: var(--Oswald);
  padding:.25em;
  font-size:2em;
  height:2em;
  margin:0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-left:1em;
}
.balance-text{
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  font-size: 1.3em;
}
.navbar{
  font-size:0.75em;
  display:flex;
  align-items: center;
  width:95%;
  justify-content: flex-end;
}
.editing-toggle{
  width:3em;
  height:3em;
  margin-left:3em;
  margin-right:3em;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}
.no-jobs{
  background-color: var( --green);
  color: var(--light-grey);
  font-family: var(--Oswald);
  font-size: 2em;
  margin-top: 0.5em;
  padding: 0.6em;
  text-align: center;
}
.jobs-display{
  margin-top:5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.job{
  background-color: var( --green);
  color: var(--light-grey);
  font-family: var(--Oswald);
  font-size: 2em;
  margin-top: 0.5em;
  padding: 0.6em;
  display: flex;
  min-width: 100%;
  flex-grow: 1;
}
.job > .download{
  text-align: right;
  flex-grow: 1;
  color: var(--white);
  text-decoration: none;
}
.job p{
  margin:0;
}
.editing-toggle.active{
  transform: rotate(45deg);
}

.logo{
  margin-right:auto;
  width:18em;
}
.dashboard{
  margin-top:3em;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content{
  width:80%;
}

.input {
  font-family: var(--Oswald);
  height:100%;
  display: flex;
  flex-direction: column;
}

.input-header{
  color:var(--white);
  font-size:2.2em;
  margin: 0;
  margin-bottom:.2em;
  font-family: var(--Oswald);
}

.input textarea, .input input{
  font-size: 2em;
  padding: .5em;
  background-color: var(--green);
  color: var(--light-grey);
  width: 100%;
  outline: 0;
  border: 0;
  font-family: var(--Oswald);
  flex-grow:1;
  resize:none;
}

.lower-container{
  width:100%;
  display:flex;
  align-items: flex-start;
  justify-content: flex-start;
  height:50vh;
}

.lower-container .left, .lower-container .right{
  width:50%;
  height:100%;
}
.lower-container .left{
  margin-right: 1em;
}
.lower-container .right{
  margin-left: 1em;
  display: flex;
  flex-direction: column;
}


.sources-container{
  width: 100%;
  flex-grow:1;
  background-color: var(--green);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  font-family: var(--Oswald);
  font-size:2em;
  color:var(--cream);
}

.sources-header{
  position:relative;
}

.sources-header span{
  position:absolute;
  right:0;
  top:0;
  font-size:2em;
  cursor: pointer;
  line-height: 1em;
  color:var(--cream)
}

.empty{
  cursor: pointer;
  justify-content: center;
}

.source{
  background-color: var(--cream);
  color: var(--green);
  font-size: 0.55em;
  width: 90%;
  padding: 0.3em;
  margin: 1em 0.5em 0em;
}

.overlay{
  height: 100vh;
  z-index: 1;
  width: 100vw;
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  top: 0;
  left: 0;
  display:flex;
  align-items: center;
  justify-content: center;
}

.overlay-body{
  width:50%;
  height:80%;
}

.overlay .input{
  height: 17.5%;
}

.overlay-splitter{
  margin-top:1em;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap:wrap;
}
.overlay-splitter > .item{
  margin-right:10%;
}

.button{
  padding:.25em;
  font-size:3em;
  text-align: center;
  color: var(--white)
}

.button.green{
  background-color: var(--green);
}

.button.red {
  background-color: var(--red);
}

.button.inactive{
  background-color: #545454;
  color:#757575 
}

.upload-container{
  display: flex;
  justify-content: flex-end;
  margin-top:1em;
  width: 100%;
}

.upload-container > .button{
  min-width:0;
}

.user-icon{
  border: 4px solid var(--cream);
  border-radius: 4em;
  height: 3em;
  margin-right: 1em;
  width: 3em;
}

.tag-header{
  display:flex;
  align-items: center;
  justify-content: center;
}

.tag-header > img {
  width: 0.7em;
  margin: 0.2em;
  cursor: pointer;
}

.tag-container{
  display:flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap:wrap;
}

.tag{
  background-color: var(--green);
  color: var(--light-grey);
  font-family: var(--Oswald);
  padding:0.25em;
  font-size:1.5em;
  margin:0.25em;
  display: inline;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag > span{
  color: var(--cream);
}
.tag > img{
  transform: rotate(45deg);
  width: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  cursor: pointer;
}

.tag > input {
  background-color: inherit;
  border: none;
  color: var(--light-grey);
  font-family: 'Oswald';
  display: inline;
  width: 5em;
  font-size: 1em;
}

@media (orientation: portrait) {
  .lower-container{
    flex-direction: column;
  }
  .lower-container > .left, .lower-container > .right{
    width:100%;
    margin-left:0em;
    margin-right:0em;
  }
  .job-misc-info{
    display:none;
  }
}